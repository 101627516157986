<template>
  <section>
    <div class="table mg-b-20">
      <div class="th">
        <span>Endorsement Type</span>
      </div>
      <div
        class="tr">
        <span>Endorsement Effective Date</span>
        <span>
          {{showHkDate(form.endorsement_effective_date)}}
        </span>
      </div>
      <div
        class="tr">
        <span>
          <v-checkbox-group
            v-model="form.endorsement_type"
            :list="
              endorsementType"
            disabled
          ></v-checkbox-group>
        </span>
      </div>
      <div class="tr cloumn_tr">
        <span>Notes:</span>
        <span>
          <!--          Show lates policy detail, but need to show what detail-->
          <!--          updated e.g.<br>-->
          <!--          Endorsement-Change Car / Endorsement-Change Driver( Include-->
          <!--          add or change driver)/ Endorsement-Change Plan Type / Endorsement- -->
          <!--          VRN / Endorsement-Change Contact detail / Endorsement-Other-->
          {{form.notes}}
        </span>
      </div>
      <div class="th">
        <span>Endorsement Premium</span>
      </div>
    </div>

    <transaction
      :form="form"
      :isMonth="false"
      @isRefresh="refresh"></transaction>
    <el-row
      :gutter="20"
      class="premium-info">

      <v-row-col class="el-col-padding">
        <v-row-col
          v-if="!isHelper&&!isHome&&!isBusiness&&!isEmployee"
          same
          :same-number="24">
          <div class="table mg-b-20">
            <div
              class="tr">
              <span>Original Annual Gross Premium</span>
              <span>
                {{premium.original_gross_premium|formatMoney(2,true)}}

              </span>
            </div>
            <div
              class="tr">
              <span>Original Annual Premium</span>
              <span>
                {{premium.original_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>Original Annual IA Levy {{refundName}}</span>
              <span>
                {{premium.original_ia_mib_ec|formatMoney(2,true)}}
              </span>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          v-if="isHelper||isHome||isEmployee"
          same
          :same-number="24">
          <div class="table mg-b-20">
            <div
              class="tr">
              <span>Original Premium</span>
              <span>
                {{premium.original_premium|formatMoney(2,true)}}

              </span>
            </div>
            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.ia_levy_vl|formatMoney(2,true)}}

              </span>
            </div>
            <div
              v-if="isHelper||isEmployee"
              class="tr">
              <span>EC Levy</span>
              <span>
                {{premium.ec_levy_vl|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>Original Gross Premium</span>
              <span>
                {{premium.original_gross_premium|formatMoney(2,true)}}
              </span>
            </div>
          </div>
        </v-row-col>

        <v-row-col
          v-if="isBusiness"
          same
          :same-number="24">
          <div class="table mg-b-20">
            <div
              class="tr">
              <span>Contents</span>
              <span>
                {{premium.original_contents_premium|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.original_contents_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Business Interruption Insurance</span>
              <span>
                {{premium.original_business_interruption_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.original_business_interruption_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Money Insurance</span>
              <span>
                {{premium.original_money_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.original_money_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>

            <div
              class="tr">
              <span>Fidelity Guarantee Insurance</span>
              <span>
                {{premium.original_fidelity_guarantee_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.original_fidelity_guarantee_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Personal Accident Insurance</span>
              <span>
                {{premium.original_personal_accident_assault_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.original_personal_accident_assault_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Public Liability Insurance</span>
              <span>
                {{premium.original_public_liability_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.original_public_liability_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Employees' Compensation Insurance</span>
              <span>
                {{premium.original_employees_compensation_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.original_employees_compensation_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>EC Levy</span>
              <span>
                {{premium.original_ec|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>Original Gross Premium</span>
              <span>
                {{premium.original_ia_mib_ec|formatMoney(2,true)}}
              </span>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          v-if="!isBusiness"
          same
          :same-number="24">
          <div class="table mg-b-20">
            <div >
              <div
                class="tr">
                <span>{{productMotor?'Base':''}} Premium</span>
                <span>
                  {{premium.base_premium|formatMoney(2,true)}}
                </span>
              </div>
              <div
                v-if="productMotor"
                class="tr">
                <span>Client Discount</span>
                <span>
                  {{premium.client_discount}} %
                </span>
              </div>
              <div
                v-if="productMotor"
                class="tr">
                <span>Loading</span>
                <span>
                  {{premium.loading}} %
                </span>
              </div>
              <div
                v-if="productMotor"
                class="tr">
                <span>NCD</span>
                <span>
                  {{premium.ncd}} %
                </span>
              </div>
              <div
                v-if="productMotor"
                class="tr">
                <span>Basic Premium</span>
                <span>
                  {{premium.basic_premium|formatMoney(2,true)}}
                </span>
              </div>
              <div
                v-if="productMotor"
                class="tr">
                <span>Additional Premium</span>Endorsement Effective Date
                <span>
                  {{premium.additional_premium|formatMoney(2,true)}}
                </span>
              </div>
              <div
                class="tr">
                <span>LA Levy</span>
                <span>
                  {{premium.ia_levy_vl|formatMoney(2,true)}}
                </span>
              </div>
            </div>
            <div
              v-if="productMotor"
              class="tr">
              <span>MIB</span>
              <span>
                {{premium.mib_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Total Premium</span>
              <span>
                {{premium.total_premium|formatMoney(2,true)}}
              </span>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          v-if="isBusiness"
          same
          :same-number="24">
          <div class="table mg-b-20">
            <div
              class="tr">
              <span>Contents</span>
              <span>
                {{premium.contents_premium|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.contents_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Business Interruption Insurance</span>
              <span>
                {{premium.business_interruption_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.business_interruption_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Money Insurance</span>
              <span>
                {{premium.money_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.money_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>

            <div
              class="tr">
              <span>Fidelity Guarantee Insurance</span>
              <span>
                {{premium.fidelity_guarantee_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.fidelity_guarantee_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Personal Accident Insurance</span>
              <span>
                {{premium.personal_accident_assault_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.personal_accident_assault_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Public Liability Insurance</span>
              <span>
                {{premium.public_liability_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.public_liability_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Employees' Compensation Insurance</span>
              <span>
                {{premium.employees_compensation_insurance_premium|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>IA Levy</span>
              <span>
                {{premium.employees_compensation_insurance_ia_levy_vl|formatMoney(2,true)}}
              </span>
            </div>
            <div
              class="tr">
              <span>EC Levy</span>
              <span>
                {{premium.ec_levy_vl|formatMoney(2,true)}}

              </span>
            </div>

            <div
              class="tr">
              <span>Total New Premium</span>
              <span>
                {{premium.total_premium|formatMoney(2,true)}}
              </span>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          v-if="!isBusiness"
          same
          :same-number="24">
          <div class="table mg-b-20">

            <div
              class="tr">
              <span>Endorsement Effective Date</span>
              <span>
                {{showHkDate(form.endorsement_effective_date)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Gross Premium Difference</span>
              <span>
                {{premium.gross_premium_difference|formatMoney(2,true)}}

              </span>
            </div>
            <div
              class="tr">
              <span>Gross Premium Difference without IA Levy {{refundName}}</span>
              <span>
                {{premium.basic_premium_difference|formatMoney(2,true)}}

              </span>
            </div>
            <div
              class="tr">
              <span>Endorsement IA Levy {{refundName}}</span>
              <span>
                {{premium.endorsement_ia_mib_ec|formatMoney(2,true)}}
              </span>
            </div>

          </div>
        </v-row-col>
        <v-row-col
          v-else
          same
          :same-number="24">
          <div class="table mg-b-20">

            <div
              class="tr">
              <span>Endorsement Effective Date</span>
              <span>
                {{showHkDate(form.endorsement_effective_date)}}
              </span>
            </div>
            <div
              class="tr">
              <span>Gross Premium Difference</span>
              <span>
                {{premium.gross_premium_difference|formatMoney(2,true)}}

              </span>
            </div>
            <div
              class="tr">
              <span>Gross Premium Difference without  Levy</span>
              <span>
                {{premium.basic_premium_difference|formatMoney(2,true)}}

              </span>
            </div>
            <div
              class="tr">
              <span>Endorsement IA Levy</span>
              <span>
                {{premium.endorsement_ia_mib_ec|formatMoney(2,true)}}

              </span>
            </div>
            <div
              class="tr">
              <span>Endorsement EC Levy</span>
              <span>
                {{premium.endorsement_ec|formatMoney(2,true)}}
              </span>
            </div>

          </div>
        </v-row-col>
      </v-row-col>

      <v-row-col class="el-col-padding">
        <v-row-col
          same
          :same-number="24">
          <div class="table mg-b-20">
            <div >
              <div
                class="tr">
                <span>Special Discount %</span>
                <span>
                  {{premium.special_discount}} %
                </span>
              </div>
              <div
                class="tr">
                <span>Special Discount Amount</span>
                <span>
                  {{premium.special_discount_vl|formatMoney(2,true)}}

                </span>
              </div>
              <div
                class="tr">
                <span>Premium Difference</span>
                <span>
                  {{premium.gross_premium_difference|formatMoney(2,true)}}

                </span>
              </div>
              <div
                v-if="!isHome"
                class="tr">
                <span>IA Levy {{ refundName }}</span>
                <span>
                  {{premium.endorsement_ia_mib_ec|formatMoney(2,true)}}

                </span>
              </div>
              <div
                v-if="isHome"
                class="tr">
                <span>IA Levy</span>
                <span>
                  {{premium.endorsement_ia_mib|formatMoney(2,true)}}

                </span>
              </div>
              <div
                v-if="isHome"
                class="tr">
                <span>EC Levy</span>
                <span>
                  {{premium.endorsement_ec|formatMoney(2,true)}}

                </span>
              </div>
              <div
                class="tr">
                <span>Handling Charge</span>
                <span>
                  {{premium.handing_charge|formatMoney(2,true)}}

                </span>
              </div>
              <div
                class="tr">
                <span>Endorsement Total Payable</span>
                <span>
                  {{premium.endorsement_total_payable|formatMoney(2,true)}}

                </span>
              </div>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          same
          :same-number="24">
          <div class="table mg-b-20">

            <div
              class="tr">
              <span>Sub Net %</span>
              <span>
                {{premium.net}} %
              </span>
            </div>
            <div
              class="tr">
              <span>Sub Net</span>
              <span>
                {{premium.net_vl|formatMoney(2,true)}}
              </span>
            </div>
            <!--            <div-->
            <!--              class="tr">-->
            <!--              <span>Net Commission $</span>-->
            <!--              <span>-->
            <!--                {{premium.net_commission_vl|formatMoney(2,true)}}-->
            <!--              </span>-->
            <!--            </div>-->
            <div
              class="tr">
              <span>Insurer Net %</span>
              <span>
                {{premium.insurer_net}} %

              </span>
            </div>
            <div
              class="tr">
              <span>Insurer Net</span>
              <span>
                {{premium.insurer_net_vl|formatMoney(2,true)}}
              </span>
            </div>
            <!--            <div-->
            <!--              class="tr">-->
            <!--              <span>Insurer Net Comm.$</span>-->
            <!--              <span>-->
            <!--                {{premium.insurer_net_commission_vl|formatMoney(2,true)}}-->

            <!--              </span>-->
            <!--            </div>-->

            <div
              class="tr">
              <span>Sub Comm.</span>
              <span>
                {{premium.referrer_commission_vl|formatMoney(2,true)}}

              </span>
            </div>
            <div
              class="tr">
              <span>RM Comm.</span>
              <span>
                {{premium.owner_commission_vl|formatMoney(2,true)}}

              </span>
            </div>
          </div>
        </v-row-col>

      </v-row-col>
    </el-row>
  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
import transaction from '@/views/components/info/transcation'
export default {
  name: 'premium',
  components:{
    transaction
  },
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    premium(){
      return this.form.premium||{}
    },
    productId(){
      return this.form.product_id
    },
    productMotor(){
      return this.productId == 10
    },
    isHelper(){
      return this.productId == 16
    },
    isEmployee(){
      return this.productId == 14
    },
    isHome(){
      return this.productId == 13
    },
    isBusiness(){
      return this.productId == 15
    },
    showEcPercent(){
      return this.productId==13
    },
    showEcHKD(){
      return [13,14,15,16,17,18].includes(this.productId)
    },
    refundName(){
      if(this.productId==10)
        return  ' MIB  '
      if(this.productId===12)
        return  ''
      else  return  'and EC Levy'
    },
    endorsementType() {
      let typesArr = this.globalOptions.endorsement_type
      let arr = []
      typesArr.filter(item => {
        if (item.product_id == this.productId)
          arr = item.items
      })
      return arr.length <= 0 ? [] : arr
    }
  },
  methods:{
    refresh(){
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped lang="scss">
.table{
  border: 1px solid #CBD5E0;
  .cloumn_tr{
    flex-direction: column;
  }

}
.premium-info{
  .el-col-padding{
    padding: 0!important;
  }
}
</style>
