var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"width-dialog-sm-and-down dialog-box",attrs:{"width":"430"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-container width-dialog-sm-and-down"},[_c('div',{staticClass:"dialog-header"},[_c('h2',[_vm._v("Mark As Settled")])]),_c('div',{staticClass:"dialog-body"},[_c('div',{staticClass:"transparent"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[(_vm.isMonth)?_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Month")]),_c('el-form-item',{attrs:{"prop":"month"}},[_c('v-date-picker',{attrs:{"format":'yyyy-MM',"type":"month"},model:{value:(_vm.formData.month),callback:function ($$v) {_vm.$set(_vm.formData, "month", $$v)},expression:"formData.month"}})],1)],1):_vm._e(),_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Settle Date")]),_c('el-form-item',{attrs:{"prop":"settle_date"}},[_c('v-date-picker',{attrs:{"type":"date"},model:{value:(_vm.formData.settle_date),callback:function ($$v) {_vm.$set(_vm.formData, "settle_date", $$v)},expression:"formData.settle_date"}})],1)],1),_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Remark")]),_c('el-form-item',{attrs:{"prop":"remark"}},[_c('v-input',{attrs:{"type":"textarea","rows":4},model:{value:(_vm.formData.remark),callback:function ($$v) {_vm.$set(_vm.formData, "remark", $$v)},expression:"formData.remark"}})],1)],1),_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Cheque")]),_c('el-form-item',{attrs:{"prop":"covernote_document_file_id"}},[_c('file-container',{attrs:{"edit":true,"fileData":{
                  url: _vm.formData.statement_file_url,
                  client_name: _vm.formData.statement_file_client_name
                },"otherData":{
                  idKey: 'statement_file_id',
                  urlKey: 'statement_file_url',
                  client_name:'statement_file_client_name'
                }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])],1)]),_c('div',{staticClass:"dialog-footer",attrs:{"align":"right"}},[_c('div',{staticClass:"btn-container"},[_c('v-button',{staticClass:"button-theme-block",attrs:{"size":"small"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel ")]),_c('v-button',{staticClass:"button-theme",attrs:{"size":"small","type":"primary"},on:{"click":_vm.handlerSave}},[_vm._v("Mark As Settled ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }